<template>
  <v-row>
      <v-col cols="12">
          <our-products/>
      </v-col>
  </v-row>
</template>

<script>
import OurProducts from '../components/Sections/OurProducts.vue'

  export default {
  components: { OurProducts},
    name: 'Products',
  }
</script>
